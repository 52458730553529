$primary-light-background-color: #ffffff;
$primary-light-color: #000;
$tablet-width: 768px;
$desktop-width: 1024px;

footer {
    position: relative;
    bottom: 0;
    background-color: #3b3f4d !important;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 40px 10px 40px 10px;
    font-family: 'Courier New', monospace, sans-serif;
    display: flex;
    border-top: 2px solid #8d8d8d;

    @media screen and (min-width: $desktop-width) {
        padding: 40px 7%;
    }

    .footer-left,
    .footer-center,
    .footer-right {
        display: inline-block;
        vertical-align: top;
        width: 30%;

        .icon-wrapper {
            width: 30px;
            float: left;
            background: #e0ac1c;
            padding: 7px 5px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            margin-right: 20px;
            margin-bottom: 20px;

            &.phone {
                margin-bottom: 40px;
            }

            svg {
                width: 24px;
                height: 24px;
                color: white;
                justify-content: center;
                align-items: center;
            }

        }

        @media screen and (max-width: $tablet-width) {
            display: block;
            width: 100%;
            margin-bottom: 40px;
            text-align: center;
        }
    }

    .footer-left {
        width: 20%;

        .footer-company-name {
            color: white;
        }

        @media screen and (max-width: $tablet-width) {
            width: 100%;
        }

        img {
            width: 30%;
        }
    }

    .footer-center {
        width: 25%;
        color: white;

        @media screen and (max-width: $tablet-width) {
            width: 100%;
        }

        .item {
            width: 100%;
            float: left;

            &.title {
                margin-bottom: 15px;

                span {
                     font-size: 25px;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin-bottom: 10px;
                    cursor: pointer;

                    a {
                        color: white;
                        text-decoration: none;
                        font-size: 19px;
                    }

                    &:hover {
                        background-color: #8d8d8d;
                        line-height: 25px;
                        padding: 5px 20px;
                        border-radius: 3px;
                    }
                }
            }
        }

        p {
            display: block;
            vertical-align: middle;
            margin: 0;
            font-weight: normal;
            font-size: 16px;
            margin-bottom: 6px;
            float: left;
            width: 78%;
            margin-top: 10px;

            a {
                color: #e0ac1c;
                text-decoration: none;;
                margin-bottom: 1px;
                display: flex;
                align-items: center;
                height: 25px;

                &:hover {
                    background-color: #8d8d8d;
                    padding: 5px 20px;
                    border-radius: 3px;
                }
            }
        }
    }

    .footer-right {
        .footer-company-name {
            color: white;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
        }

        .footer-company-about {
            line-height: 20px;
            color: white;
            font-size: 13px;
            font-weight: normal;
            margin: 0;

            @media screen and (max-width: $tablet-width) {
                margin-top: 30px;
                text-align: justify;
                display: inline-block;
            }

            .title {
                display: block;
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 20px;

                @media screen and (max-width: $tablet-width) {
                    text-align: center;
                }
            }
        }
    }

    h3 {
        color: #454547;
        font: normal 36px 'Cookie', cursive;
        margin: 0;

        span {
            color: #e0ac1c;
        }
    }

    @media (max-height: 800px) {
        footer {
            position: static;
        }
    }

    @media (max-width: $tablet-width) {
        display: block;
    }
}

.online-form-modal {
    .rodal-dialog {
        width: 100%;
        padding-top: 30px;

        h2 {
            text-align: center;
        }

        form {
            width: 80%;
            background-color: #4cd964;
        }
    }
}
