$tablet-width: 768px;
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: #e0ac1c;
}

.subcategory-page {
    font-family: "Courier New", monospace, sans-serif;
    background-color: #e5e5e5;

    .header {
        min-height: 400px;

        .inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 400px;

            .center {
                width: 60%;
                background-color: hsla(0,0%,81%,.82);
                border-radius: 10px;
                padding: 30px 30px;

                h2 {
                    text-align: center;
                }

                hr {
                    width: 70%;
                    height: 4px;
                    background-color: white;
                    border: none;
                }

                p {
                    text-align: center;
                }
            }
        }
    }

    .content {
        min-height: 650px;
        padding: 40px 0;

        & > .left {
            width: 50%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 650px;

            .image-gallery {
                width: 80%;

                .image-gallery-content .image-gallery-slide .image-gallery-image {
                    max-height: 600px;
                }
            }
        }

        & > .right {
            width: 40%;
            float: left;

            ul {
                list-style: none;
                padding: 0;

                li {
                    line-height: 35px;
                    //justify-content: flex-start;
                    //font-size: 17px;

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 15px;
                        color: green;
                        float: left;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}


