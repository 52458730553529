$primary-light-background-color: #ffffff;
$primary-light-color: #000;
$tablet-width: 768px;
$desktop-width: 1024px;

.feature-card-container {
    text-align: center;
    width: 80%;
    margin: 150px auto auto;

    @media (max-width: $tablet-width) {
        width: 90%;
        margin: 0;
        margin-left: 3%;
        margin-top: 70px;
    }

    .feature-card {
        width: 30%;
        margin-left: 2%;
        display: inline-block;

        @media (max-width: $tablet-width) {
            width: 100%;
            margin-bottom: 20px;
        }

        .cols{
            display: -webkit-box;
            display: -ms-flexbox;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            cursor: pointer;

            .col{
                @media (max-width: $tablet-width) {
                    width: 100%;
                }
            }
        }

        .container{
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-perspective: 1000px;
            perspective: 1000px;

            .front,
            .back{
                background-size: cover;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
                border-radius: 10px;
                background-position: center;
                -webkit-transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                text-align: center;
                min-height: 350px;
                height: auto;
                border-radius: 10px;
                color: #fff;
                font-size: 1.5rem;

                .inner{
                    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
                    transform: translateY(-50%) translateZ(60px) scale(0.94);
                    top: 50%;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    padding: 2rem;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: 1px solid transparent;
                    -webkit-perspective: inherit;
                    perspective: inherit;
                    z-index: 2;

                    p {
                        font-size: 22px;
                        margin-bottom: 20px;
                        position: relative;

                        @media (max-width: $tablet-width) {
                            font-size: 19px;
                            margin-bottom: 15px;
                        }

                        &:after{
                            content: '';
                            width: 4rem;
                            height: 2px;
                            position: absolute;
                            background: #C6D4DF;
                            display: block;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            bottom: -.75rem;
                        }
                    }

                    span{
                        color: rgba(255,255,255,0.7);
                        font-family: 'Montserrat';
                        font-weight: 300;
                    }
                }
            }

            .back {
                background: #ffd400;
                background: -webkit-linear-gradient(45deg,  #ffd400 0%, #6c5800 100%);
                background: -o-linear-gradient(45deg,  #ffd400 0%,#6c5800 100%);
                background: linear-gradient(45deg,  #ffd400 0%, #6c5800 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .front {
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .front:after{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                content: '';
                display: block;
                opacity: .6;
                background-color: #000;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                border-radius: 10px;
            }

            &:hover .front,
            &:hover .back{
                -webkit-transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform .9s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .9s cubic-bezier(0.4, 0.2, 0.2, 1);
            }

            &:hover .back{
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            &:hover .front {
                -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

        }
    }
}
