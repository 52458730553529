$tablet-width: 768px;

.multi-action-wrapper {

    &.active {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.52);;
        position: fixed;
        z-index: 6;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    .multi-action {
        display: inline-block;
        position: fixed;
        z-index: 4;
        width: 56px;
        height: 56px;
        margin: 300px 0 0 -28px;
        right: 30px;
        bottom: 100px;
        cursor: pointer;

        @media (max-width: $tablet-width) {
            right: 13px !important;
        }

        .rpt-container {
            .rpt-hover {
                font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
                h3 {
                    margin: 0;
                    border-bottom: 1px solid darkgrey;
                }

                span {
                    color: #4b4b4b;
                }
            }
        }

        .action-button {
            position: absolute;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            border: 0;
            outline: 0;
            background: rgba(0, 203, 252, 1);
            font-size: 24px;
            color: white;
            z-index: 2;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            transition: all .3s;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.active {
                box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19), 0 12px 15px 0 rgba(0, 0, 0, 0.24);
            }

            &.active ~ .actions {

                li {
                    transition: all .3s;
                    transform: scale(1);

                    &:nth-child(1) {margin-top: -78px;}
                    &:nth-child(2) {margin-top: -148px;}
                    &:nth-child(3) {margin-top: -152px;}
                    &:nth-child(4) {margin-top: -200px;}
                    &:nth-child(5) {margin-top: -248px;}
                }
            }

            span {
                transition: all .3s;
            }

            &.active:not(.no-rotate) svg {
                transform: scale(1.5) rotate(0deg);
            }

            svg {
                transition: opacity .6s ease-out;
                opacity: 1;
            }

            @keyframes waves {
                0% {
                    -webkit-transform: scale(0.2, 0.2);
                    transform: scale(0.2, 0.2);
                    opacity: 0;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                }
                50% {
                    opacity: 0.9;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
                }
                100% {
                    -webkit-transform: scale(0.9, 0.9);
                    transform: scale(0.9, 0.9);
                    opacity: 0;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                }
            }

            .waves {
                position: absolute;
                width: 150px;
                height: 150px;
                background: rgba(0, 203, 252, 0.3);
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                border-radius: 100%;
                right: -48px;
                bottom: -47px;
                z-index: -1;
                -webkit-animation: waves 3s ease-in-out infinite;
                animation: waves 3s ease-in-out infinite;
            }

            .wave-1 {
                -webkit-animation-delay: 0s;
                animation-delay: 0s;
            }

            .wave-2 {
                -webkit-animation-delay: 1s;
                animation-delay: 1s;
            }

            .wave-3 {
                -webkit-animation-delay: 2s;
                animation-delay: 2s;
            }
        }

        .actions {
            position: absolute;
            list-style: none inside none;
            margin: 0 0 0 0;
            padding: 0;
            width: auto;
            float: left;
            background-color: transparent;
            top: 8px;
            left: 8px;
            z-index: 1;
            width: 56px;
            height: 56px;
            border-radius: 50%;

            li {
                position: absolute;
                display: block;
                width: 56px;
                height: 56px;
                border-radius: 50%;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                margin: 0;
                background: #212121;
                color: #ffffff;
                transition: all .3s;
                transform: scale(.3);
                display: flex;
                align-items: center;
                right: 8px;

                &:nth-child(1) {background: #ff9800;}
                &:nth-child(2) {background: #2196F3;}
                &:nth-child(3) {background: #FFEB3B;}
                &:nth-child(4) {background: #E91E63;}
                &:nth-child(5) {background: #4CAF50;}

                a {
                    background: inherit;
                    color: inherit;
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    line-height: 56px;
                    text-align: center;
                    display: none;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 56px;
                        height: 56px;
                    }

                    &.active {
                        display: flex;
                    }
                }

                span {
                    position: absolute;
                    right: 100%;
                    margin-right: 20px;
                    padding: 4px 15px;
                    background-color: #ffffff;
                    border-radius: 3px;
                    color: black;
                    display: none;

                    &.active {
                        display: inline-block;
                    }
                }

                &:active {
                    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}
