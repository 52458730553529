$tablet-width: 768px;

.offers {
    color: #ffd800;
    text-align: center;

    p, h2 {
        color: black;
    }

    .react-multi-carousel-list {
        min-height: 190px;

        h2 {
            color: #ffd800;
        }

        .react-multi-carousel-dot-list {
            bottom: 5px;

          li {
            margin-right: 20px;

            @media (max-width: $tablet-width) {
              margin-right: 11px;
            }
          }
        }
    }
}
