$tablet-width: 768px;
$desktop-width: 1024px;
$link-color: #424242;
.product-page {
    padding: 0 10%;
    min-height: 500px;
    z-index: 2;
    position: relative;

    @media (max-width: $tablet-width) {
        padding: 10px 0 0;
        z-index: 2;
        position: relative;
    }

    .left {
        width: 19%;
        float: left;
        min-height: 250px;
        border-right: 1px solid black;

        @media (max-width: $tablet-width) {
            display: none;
        }

        h2 {
            margin-bottom: 5px;
        }

        .categories {
            margin: 0;
            padding: 0;
            border: 0;
            vertical-align: baseline;
            font: inherit;
            font-size: 100%;

            .category-item {
                list-style: none;
                align-items: flex-start;
                display: block;
                flex: 1 1 calc(100% - 40px);
                padding: 7px 20px;
                width: calc(100% - 40px);
                text-decoration: none;
                font-size: 15px;
                line-height: 1.4;
                color:$link-color;
                cursor: pointer;

                &:hover {
                    background-color: #8d8d8d;
                    color: white;
                }
            }
        }
    }

    .left-mobile {
        display: none;

        @media (max-width: $tablet-width) {
            display: block;
        }

        & > p {
            padding: 0 20px;
            line-height: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid dimgrey;

            svg {
                color: dimgrey;
            }

            span {
                margin-left: 10px;
            }
        }

        & > div {
            position: absolute;
            top: 0;

            .sidebar {
                background-color: white;

                .bm-item-list {
                    margin-top: 50px;

                    h2 {
                        text-align: center;
                        padding-bottom: 20px;
                        border-bottom: 1px solid black;
                    }

                    .categories {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        vertical-align: baseline;
                        font: inherit;
                        font-size: 100%;

                        .category-item {
                            list-style: none;
                            align-items: flex-start;
                            display: block;
                            flex: 1 1 calc(100% - 40px);
                            padding: 7px 20px;
                            width: calc(100% - 40px);
                            text-decoration: none;
                            font-size: 15px;
                            line-height: 1.4;
                            color:$link-color;
                            cursor: pointer;

                            &:hover {
                                background-color: #8d8d8d;
                                color: white;
                            }
                        }
                    }
                }


            }
        }
    }

    .right {
        width: 80%;
        float: left;
        min-height: 500px;
        padding-top: 30px;

        @media (max-width: $tablet-width) {
            width: 100%;
            z-index: 2;
            position: relative;
        }

        .product-item {
            width: 24%;
            float: left;
            margin: 10px 0;

            @media (max-width: $tablet-width) {
                width: 49%;
            }

            &:hover {
                -webkit-box-shadow: 0px 0px 7px 6px #858585;
                box-shadow: 0px 0px 7px 6px #858585;
                z-index: 2;
                position: relative;
            }

            a {
                margin-left: 1%;
                text-decoration: none;
                color: black;
                text-align: center;
                img {
                    width: 95%;
                    max-width: 350px;
                }

                .title {
                    font-size: 14px;
                }
            }
        }
    }

    .title-div {
        & > h2 {
            padding-top: 10px;
            text-align: center;
            padding-bottom: 20px;
        }
    }

    .load-more {
        width: 100%;
        text-align: center;

        span {
            text-align: center;
            padding: 10px 50px;
            background-color: lightgrey;
            font-size: 22px;
            border-radius: 4px;
            cursor: pointer;

            @media (max-width: $tablet-width) {
                border: none;
                font-size: 18px;
            }
        }
    }
}

footer {
    @media (max-width: $tablet-width) {
        border: none;
    }
}
