@import 'https://fonts.googleapis.com/css?family=Caveat|Sofia|Cairo';

.slider {
    margin-top: 2px;
    margin-bottom: 2px;
    height: 550px;
    font-family: 'Cairo', sans-serif;
}

.slider .item {
    background: #00558b;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 0 10px!important;
    padding: 2%;
    position: relative;
    text-align: center;
}

.slider .item a {
    text-decoration: none;
}

.slider .item a:visited {
    color: white;
}

.slider .item a:hover {
     color: black;
 }

.slider .track .slide {
    text-align: center;
    background-position: center center !important;
}

.slider .track .slide::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.35);
}

.slider .track .slide .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
    z-index:2;
}

.slider .track .slide .center p {
    color: #FFFFFF;
    font-size: 20px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateY(20px);
    transition-delay: .9s;
}

.slider a.previousButton, .slider a.nextButton {
    z-index: 1;
}

.slider .track .slide .center h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 45px;
    line-height: 1;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
}

.slider button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="5" /><feOffset dx="1" dy="6" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
    -webkit-transition: background .5s ease, color .5s ease;
    transition: background .5s ease, color .5s ease;
    border: none;
    background: #ffd800;
    border-radius: 30px;
    text-transform: uppercase;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 40px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer
}

.slider .track .slide .center button {
    font-weight: bold;
}

.slider .track .slide .center button a {
    text-decoration: none;
    color: inherit;
}

.slider button.non-primary {
    border: 1px solid white;
    background: unset;
    color: white;
    margin-left: 30px;
}

.slider .track .slide .center button:hover {
    color: #ffffff;
    background: #222222;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="5" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.2)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
    font-size: 20px;

}

.slider .nextButton, .slider .previousButton, polygon {
    fill: #ffd800

}
@media screen and (max-width: 756px) {
    .slider {
        height: 500px;
    }

    .slider .track .slide .center h1 {
        font-size: 25px;
        margin-bottom: 60px;
    }

    .slider button.non-primary {
        margin-left: 10px;
    }

    .slider .track .slide .center button {
        padding: 15px 20px;
        border-radius: 10px;
        font-size: 13px;
    }
}
