$tablet-width: 768px;
$space-before-right: 47px;

.product-detail-page {
    min-height: 500px;
    padding: 30px 15%;

    @media (max-width: $tablet-width) {
        padding: 30px 1%;
        min-height: 800px;
        z-index: 2;
        position: relative;
    }

    .left {
        min-height: 450px;
        width: 40%;
        float: left;

        @media (max-width: $tablet-width) {
            width: 100%;
        }

        .image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 98%;
                cursor: pointer;
            }
        }
    }

    .right {
        width: 50%;
        float: left;
        min-height: 400px;

        @media (max-width: $tablet-width) {
            width: 100%;
        }

        .navigation {
            padding: 5px 0;
            padding-left: 40px;

            @media (max-width: $tablet-width) {
                padding-left: 5px;
            }

            a {
                margin: 0 7px;
                color: dimgrey;
                text-decoration: none;

                &.product {
                    color: black;
                }
            }
        }

        h2 {
            margin-left: $space-before-right;
            padding-bottom: 20px;
            border-bottom: 1px solid grey;

            @media (max-width: $tablet-width) {
                margin-left: 10px;
            }
        }

        .description {
            margin-left: $space-before-right;
            margin-top: 40px;

            @media (max-width: $tablet-width) {
                margin-left: 10px;
            }
        }
    }

    .rodal {
        .rodal-close {
            display: none;
        }

        img {
            width: 100%;
        }
    }
}
