$primary-light-background-color: #ffffff;
$primary-light-color: #000;
$tablet-width: 768px;
$desktop-width: 1024px;

@import 'https://fonts.googleapis.com/css?family=Caveat|Sofia|Cairo';

.contact-container {
    .details {
        text-align: center;
        margin-top: 50px;
        width: 80%;
        margin-left: 10%;

        @media (max-width: $tablet-width) {
            width: 86%;
            margin-left: 7%;
            margin-top: 15px;
        }

        .header {
            h1 {
                font-size: 40px;
            }

            h4 {
                font-weight: 100;
            }
        }

        .body {
            margin-top: 100px;
            margin-bottom: 100px;

            @media (max-width: $tablet-width) {
                margin-top: 60px;
            }

            .item {
                width: 27%;
                text-align: left;
                padding: 0 30px;
                float: left;
                min-height: 150px;

                @media (max-width: $tablet-width) {
                    padding: 0;
                    width: 100%;
                    min-height: 100px;
                }

                .value {
                    font-family: sans-serif;
                    font-weight: 100;
                }
            }
        }

        .map-container {
            iframe {
                margin-top: 50px;
            }
        }
    }
}
