$tablet-width: 768px;

.advantages {
    min-height: 200px;
    text-align: center;
    background-color: #f1f1f1 !important;
    color: black;
    padding-top: 20px;
    z-index: 3;
    position: relative;

    h2 {
        font-weight: 100;
        margin-bottom: 0;
    }

    .react-multi-carousel-list {
        min-height: 170px;

        ul {
            li {
                & > div {
                    text-align: center;
                }
            }
        }

        .advantage-item {
            img {
                width: 50px;
                max-height: 50px;
            }
        }

        .react-multi-carousel-dot-list {
            bottom: 5px;

            li {
                margin-right: 20px;

                @media (max-width: $tablet-width) {
                    margin-right: 11px;
                }
            }
        }
    }
}

