$tablet-width: 768px;
:root {
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: #e0ac1c;
}

.category-page {
    width: 100%;
    background-color: #e5e5e5;

    .header {
        min-height: 450px;

        @media (max-width: $tablet-width) {
            min-height: 600px;
            display: flex;
        }

        .inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 450px;

            .center {
                width: 60%;
                background-color: hsla(0,0%,81%,.82);
                border-radius: 10px;
                padding: 30px 30px;
                font-family: "Courier New", monospace, sans-serif;

                h2 {
                    text-align: center;
                }

                hr {
                    width: 70%;
                    height: 4px;
                    background-color: white;
                    border: none;
                }

                p.super-category {
                    text-align: center;
                }
            }
        }

        &.super-category {

            p {
                text-align: center;
            }
        }
    }

    .subcategories {
        min-height: 500px;
        line-height: 1.6;
        font-family: sans-serif;
        padding: 0 10%;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: $tablet-width) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            padding-bottom: 50px;
        }

        .card {
            color: var(--clr-neutral-100);
            background-size: cover;
            padding: 70px 0 0;
            width: 23%;
            border-radius: 0.5rem;
            overflow: hidden;
            transition: transform 500ms ease;
            margin-right: 2%;
            min-height: 300px;
            text-transform: capitalize;

            @media (max-width: $tablet-width) {
                width: 94%;
                padding: 70px 0 0;
                margin: 0 3%;
            }
        }

        .card:hover,
        .card:focus-within {
            transform: scale(1.05);
        }

        .card-content {
            --padding: 20px;
            padding: var(--padding);
            background: linear-gradient(
                            hsl(0 0% 0% / 0),
                            hsl(20 0% 0% / 0.3) 20%,
                            hsl(0 0% 0% / 1)
            );
            min-height: 310px;
        }

        .card-title {
            position: relative;
            width: auto;
        }

        .card:hover .card-title::after,
        .card:focus-within .card-title::after {
            transform: scaleX(1);
        }

        .card-title::after {
            content: "";
            position: absolute;
            height: 4px;
            width: calc(100% + var(--padding));
            left: calc(var(--padding) * -1);
            bottom: -2px;
            background: var(--clr-accent-400);
            transform-origin: left;
            transition: transform 500ms ease;
        }

        @media (hover) {
            .card-content {
                transform: translateY(70%);
                transition: transform 500ms ease;
            }

            .card-content > *:not(.card-title) {
                opacity: 0;
                transition: opacity 500ms linear;
            }

            .card:hover .card-content > *:not(.card-title),
            .card:focus-within .card-content > *:not(.card-title) {
                opacity: 1;
                transition-delay: 700ms;
            }

            .card:hover .card-content,
            .card:focus-within .card-content {
                transform: translateY(0);
                transition-delay: 500ms;
            }

            .card:focus-within .card-content {
                transition-duration: 0ms;
            }

            .card-title::after {
                transform: scaleX(0);
            }
        }

        .button {
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
            color: var(--clr-neutral-900);
            background-color: var(--clr-accent-400);
            padding: 0.5em 1.25em;
            border-radius: 0.25rem;
        }

        .button:hover,
        .button:focus {
            background-color: var(--clr-neutral-100);
        }

        .card-body {
            color: rgb(255, 255, 255/0.85);
        }

        @media (prefers-reduced-motion: reduce) {
            *,
            *::before,
            *::after {
                animation-duration: 0.01ms !important;
                animation-iteration-count: 1 !important;
                transition-duration: 0.01ms !important;
                scroll-behavior: auto !important;
                transition-delay: 0ms !important;
            }
        }
    }
}
