$tablet-width: 768px;

.contact-modal {
    .rodal-dialog {
        padding: 0;

        div {
            & > p {
                text-align: center;
                font-size: 18px;
            }
        }

        .options {
            .option {
                appearance: none;
                filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
                transition: background .5s ease, color .5s ease;
                border: none;
                background: #ffd800;
                border-radius: 30px;
                text-transform: uppercase;
                box-sizing: border-box;
                padding: 15px 40px;
                font-weight: 400;
                font-size: 13px;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    text-decoration: none;
                    color: #343434;
                    margin-left: 20px;
                }

                img {
                    width: 25px;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.slider {
    .track {
        .slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            .center {
                background-color: rgb(206 206 206 / 82%);
                height: 60%;
                border-radius: 10px;

                @media (max-width: $tablet-width) {
                    width: 75%;
                    height: 50%;
                }

                h1 {
                    color: black;
                    top: 30%;
                    position: relative;

                    @media (max-width: $tablet-width) {
                        margin-bottom: 0;
                    }
                }

                button {
                    color: black;
                    top: 40%;
                    position: relative;

                    @media (max-width: $tablet-width) {
                        top: unset;
                        margin-top: 120px;
                    }

                    &.non-primary {
                        border-color: white;
                        background-color: white;
                    }
                }
            }
        }
    }

    a.previousButton {
        @media (max-width: $tablet-width) {
            left: 5px!important;
        }
    }

    a.nextButton {
        @media (max-width: $tablet-width) {
            right: 5px!important;
        }
    }
}
