.notification {
    font-weight: 300;
    line-height: 1.6em;
    font-family: Hind,Arial,sans-serif;
    font-size: 12px;
    color: #777;
}

.Toastify__toast,
.Toastify__toast--error {
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .4);
}

.Toastify__toast::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #3366BB;
}

.Toastify__toast--success::before {
    background-color: #008C14;
}

.Toastify__toast--error::before {
    background-color: #cc3000;
}
