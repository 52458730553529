$primary-light-background-color: #ffffff;
$primary-light-color: #000;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-width-small: 1333px;

@import 'https://fonts.googleapis.com/css?family=Caveat|Sofia|Cairo';

.top-navbar-desktop {
    background-color: $primary-light-background-color;
    position: relative;
    height: 70px;
    font-family: 'Cairo', sans-serif;

    @media (min-width: 1333px) {
        padding: 0 5%;
    }

    @media (max-width: $desktop-width-small) {
        padding: 0;
    }

    @media (max-width: $tablet-width) {
        display: none;
    }

    .phone-button {
        padding: 11px 25px;
        display: flex;

        @media (max-width: $desktop-width-small) {
            padding: 14px 10px;
        }

        img {
            float: left;
            width: 35px;
        }

        .text {
            float: left;
            margin-left: 15px;
            line-height: 25px;
            font-size: 17px;

            strong {
                display: block;
            }
        }
    }

    & > a {
        float: right;
        display: block;
        color: #2c2c2c;
        text-align: center;
        padding: 25px 24px;
        text-decoration: none;
        font-size: 19px;
        font-family: Roboto Condensed, serif;
        font-weight: 500;
        letter-spacing: 0.2px;
        background-color: #ffffff;

        @media (max-width: $desktop-width-small) {
            padding: 14px 10px;
        }

        a span {
            font-size: 25px;
        }

        &:hover {
            background-color: #ddd;
        }

        &.active {
            color: black;
            font-weight: bold;
        }

        &.logo {
            background-color: #ffffff !important;
            color: black;
            float: right;
            padding: 0;
            padding-left: 40px;
            max-height: 40px;
        }

        &.goods {
            .goods-popup {
                display: none;

                position: absolute;
                top: 100%;
                z-index: 7;
                background: white;
                width: 50%;
                left: 25%;
                border-top: 3px solid #e0ac1c;
                box-shadow: 0 1px 4px rgb(0 0 0 / 56%);

                @media (max-width: 1450px) {
                    width: 70%;
                    left: 15%;
                }

                .column {
                    margin-top: 10px;
                    text-align: left;
                    padding: 10px 0;
                    //font-family: Roboto Condensed, serif;

                    & > a {
                        font-size: 17px;
                        font-weight: bold;
                        text-decoration: none;
                        text-align: left;
                        color: black;
                        text-transform: uppercase;
                        margin: 0 30px;
                    }

                    ul {
                        list-style: none;
                        text-align: left;
                        padding-left: 0;
                        margin-top: 10px;

                        li {
                            margin: 4px 0;
                            text-transform: capitalize;

                            &:hover {
                                background-color: #d9d9d9;
                                border-radius: 3px;
                                padding: 5px;
                            }

                            a {
                                text-decoration: none;
                                font-size: 15px;
                                font-weight: 300;
                                color: #252525;
                                margin: 0 30px;

                                &:hover {
                                    font-size: 17px;
                                    padding: 4px 0;
                                    color: black;
                                }
                            }
                        }
                    }
                }
            }

            &:hover {
                .goods-popup {
                    display: flex;
                }
            }
        }
    }

    .navbar-bar-icon {
        display: none;
    }

    #language-selector {
        width: 10%;
        right: 10px;
        /*position: absolute;*/
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 5;
        float: right;
        margin: 0 0 0 35px;
        display: block;

        @media (max-width: $desktop-width) {
            width: 20%;
        }

        @media (max-width: $desktop-width-small) {
            margin: 0 0 0 15px;
            width: 12%;
        }
    }
}

.navbar-mobile {
    display: none;

    @media (max-width: $tablet-width) {
        display: block;
    }

    .header {
        min-height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
            width:45px;
            height:45px;
            color: #262626;
            margin-left: 7px;
        }

        img {
            position: absolute;
            margin-left: 30%;
            width: 150px;
        }
    }

    .bm-menu-wrap {
        background-color: white;

        .sidebar {
            ul {
                padding: 0;
                list-style: none;

                li {
                    padding: 5px 20px;
                    padding-left: 0;
                    margin: 10px 20px;
                    border-radius: 3px;

                    a {
                        text-decoration: none;

                        span {
                            background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
                            background: initial;
                            color: #343434;
                            font-size: 25px;
                            font-weight: 300;
                            height: auto;
                            opacity: 1;
                            transition: .2s;
                            width: auto;
                        }
                    }
                }

                #language-selector {
                    margin: 20px 20px;
                }
            }
        }
    }
}
