@import 'https://fonts.googleapis.com/css?family=Quicksand|Raleway';
$tablet-width: 768px;
$desktop-width: 1200px;

.home {
    .feature-container-1 {
        min-height: 600px;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 50px;

        @media (min-width: $desktop-width) {
            min-height: 700px
        }

        @media (max-width: $tablet-width) {
            padding-left: 5%;
            padding-right: 5%;
            min-height: 700px
        }

        &.third {
            @media (max-width: $tablet-width) {
                padding-left: 0%;
                padding-right: 0%;
            }

            img {
                @media (max-width: $tablet-width) {
                    width: 100%!important;
                    max-width: 100%!important;
                }
            }
        }

        .column {
            width: 50%;
            float: left;
            font-family: "Raleway", sans-serif;

            img {
                max-width: 85%;
            }

            &.third {
                float: right;
            }

            @media (max-width: $tablet-width) {
                width: 100%;
            }

            h1 {
                position: absolute;
                font-size: 120px;
                right: 10%;
                color: #debc00;
                opacity: 0.4;

                @media (max-width: $tablet-width) {
                    margin-top: 20px;
                    left: 0;
                }
            }

             h2 {
                 display: inline-block;
                 font-size: 50px;
                 font-weight: bold;
                 width: 50%;
                 z-index: 2;
                 position: relative;

                 @media (max-width: $tablet-width) {
                     font-size: 27px;
                     width: 90%;
                     margin-left: 5%;
                 }
            }

            h4 {
                font-size: 30px;
                margin: 0;
                padding: 0;
                width: 70%;
                z-index: 1;

                @media (max-width: $tablet-width) {
                    font-size: 20px;
                    width: 85%;
                    margin-left: 10%;
                    z-index: 2;
                    position: relative;
                }
            }

            p {
                width: 70%;
                line-height: 31px;

                @media (max-width: $tablet-width) {
                    font-size: 15px;
                    width: 85%;
                    margin-left: 10%;
                }
            }
        }
    }

    .feature-container-2 {
        min-height: 500px;
        padding-left: 20%;
        padding-right: 10%;
        padding-top: 50px;
        color: white;
        box-shadow: inset -550px 0px 0px 34px white;
        background-color: #debc00;

        @media (max-width: $desktop-width) {
            min-height: 650px;
            padding-left: 5%;
            padding-right: 10%;
        }

        @media (max-width: $tablet-width) {
            padding: 0;
            background-color: #debc00;
        }

        .column {
            width: 50%;
            float: left;
            font-family: "Raleway", sans-serif;

            @media (max-width: $tablet-width) {
                width: 100%;
            }

            &.first {
                width: 50%;

                @media (max-width: $tablet-width) {
                    width: 90%;
                    padding-left: 5%;
                    padding-right: 5%;
                    background-color: #debc00;
                }
            }

            img {
                max-width: 85%;
                @media (max-width: $tablet-width) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            h1 {
                position: absolute;
                font-size: 120px;
                left: 5%;
                color: #ffec4a;
                opacity: 0.4;
            }

            h2 {
                display: inline-block;
                font-size: 50px;
                font-weight: bold;
                z-index: 2;
                position: relative;

                @media (max-width: $tablet-width) {
                    font-size: 28px;
                    width: 90%;
                    margin-left: 5%;
                }
            }

            h4 {
                font-size: 30px;
                margin: 0;
                padding: 0;
                width: 80%;
                line-height: 50px;

                @media (max-width: $tablet-width) {
                    font-size: 20px;
                    width: 85%;
                    margin-left: 10%;
                    z-index: 2;
                    position: relative;
                }
            }

            p {
                width: 70%;
                line-height: 31px;

                @media (max-width: $tablet-width) {
                    font-size: 15px;
                    width: 85%;
                    margin-left: 10%;
                }
            }
        }
    }

    .review-container {
        background-color: #debc00;
        min-height: 400px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;

        @media (max-width: $tablet-width) {
            min-height: 560px;
        }

        .reviews {
            width: 75%;
            background-color: white;
            text-align: center;
            border-radius: 10px;

            @media (max-width: $tablet-width) {
                height: 500px;
                width: 92%;
                border-radius: 5px;
            }

            h2 {
                margin-bottom: 0;
                margin-top: 30px;
            }

            .slider {
                height: 250px;
                width: 100%;
                border-radius: 10px;

                @media (max-width: $tablet-width) {
                    height: 450px;
                    width: 100%;
                }


                a {
                    &.previousButton {
                        @media (max-width: $tablet-width) {
                            left: 0;
                        }
                    }

                    &.nextButton {
                        @media (max-width: $tablet-width) {
                            right: 0;
                        }
                    }

                }

                .track .slide {
                    font-family: "Courier New", monospace, sans-serif;

                    &::before {
                        background-color: white;
                    }

                    .center {
                        height: 80%;
                        background-color: white!important;
                        @media (max-width: $tablet-width) {
                            width: 70%;
                            text-align: justify;
                            height: 100%;
                        }
                    }

                    p {
                        color: black;
                        font-weight: 100;
                        margin: 0;
                        width: 100%;
                        display: inline-block;
                        max-width: unset;
                        font-size: 17px;

                        @media (max-width: $tablet-width) {
                            font-size: 15px;
                        }
                    }

                    h4 {
                        font-weight: 100;
                        margin-top: 50px;
                        text-align: center;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

    }
}
