$tablet-width: 768px;

.online-form {
    min-height: 120px;
    background-color: #debc00;

    @media screen and (max-width: $tablet-width) {
        padding: 20px;
    }

    &> div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    form {
        textarea {
            resize: none;
        }

        input[type=text], textarea {
            width: 100%;
            padding: 12px 20px;
            margin: 8px 0;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
        }

        input[type=submit] {
            width: 100%;
            background-color: #4CAF50;
            color: white;
            padding: 14px 20px;
            margin: 8px 0;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        input[type=submit]:hover {
            background-color: #45a049;
        }

        div {
            border-radius: 5px;
            background-color: #f2f2f2;
            padding: 20px;
        }
    }

    p {
        font-size: 35px;

        @media screen and (max-width: $tablet-width) {
            font-size: 25px;
            text-align: center;
        }
    }

    button {
        appearance: none;
        filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
        transition: background .5s ease, color .5s ease;
        border: none;
        background: white;
        border-radius: 30px;
        text-transform: uppercase;
        box-sizing: border-box;
        padding: 15px 40px;
        font-weight: 400;
        font-size: 13px;
        cursor: pointer;

        &:hover {
            color: #ffffff;
            background: #222222;
            -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
            font-size: 20px;
        }
    }
}
