$primary-light-background-color: #ffffff;
$primary-light-color: #000;
$tablet-width: 768px;
$desktop-width: 1024px;

.about-page {
    .header {
        @media (max-width: $tablet-width) {
            padding-top: 10px;
        }

        .gallery {
            display: -ms-flexbox; /* IE10 */
            display: flex;
            -ms-flex-wrap: wrap; /* IE10 */
            flex-wrap: wrap;
            padding: 0 4px;

            .column {
                -ms-flex: 24%; /* IE10 */
                flex: 24%;
                max-width: 25%;
                padding: 0 4px;

                @media screen and (max-width: 800px) {
                    -ms-flex: 50%;
                    flex: 50%;
                    max-width: 50%;
                }

                @media screen and (max-width: 600px) {
                    -ms-flex: 100%;
                    flex: 100%;
                    max-width: 100%;
                }

                img {
                    margin-top: 8px;
                    vertical-align: middle;
                    width: 100%;
                    z-index: 2;
                    position: relative;
                }

                &.third {
                    img {
                        -webkit-box-shadow: -100px 35px 1px 2px #ffd800;
                        box-shadow: -100px 35px 1px 2px #ffd800;
                    }
                }

                &.second {
                    z-index: 3;
                }
            }
        }
    }

    .description {
        width: 100%;
        padding: 0 25%;
        min-height: 300px;
        max-height: 1000px;

        @media (max-width: $tablet-width) {
            min-height: 700px;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
        }

        .column {
            width: 25%;
            float: left;
            font-family: "Courier New", monospace, sans-serif;
            line-height: 25px;

            @media (max-width: $tablet-width) {
                width: 100%;
                text-align: justify;
            }
        }
    }

    .separator {
        margin-top: 80px;
        margin-bottom: 40px;
        padding: 0 20%;

        @media (max-width: $tablet-width) {
            padding: 0;
            padding-left: 10%;
            padding-right: 10%;
        }

        hr {
            width: 100%;
        }
    }

    .offers-container {
        font-family: "Courier New", monospace, sans-serif;
        .offers {
            width: 70%;
            margin-left: 15%;
        }
    }
}
