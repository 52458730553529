$tablet-width: 768px;

body {
  margin: 0;
}

.scroll-to-top {
    background-color: #debc00!important;
    z-index: 5!important;
    bottom: 0!important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @media (max-width: $tablet-width) {
        right: 18px!important;
        bottom: 15px!important;
        padding: 0!important;
        border-radius: 5px;
    }

    svg {
        fill: white!important;
        padding: 0!important;
        margin: 0!important;
        position: relative;
    }
}
